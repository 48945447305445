var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[(_vm.show)?_c('div',{class:_vm.modalClassName,attrs:{"transition":"modal"},on:{"mousedown":_vm.handleMouseDown}},[_c('div',{class:{
        'modal-container rtl:text-right shadow-md max-h-full relative bg-white dark:bg-slate-800 skip-context-menu': true,
        'overflow-visible': _vm.overflowVisible,
        'overflow-auto': !_vm.overflowVisible,
        'rounded-xl w-[37.5rem]': !_vm.fullWidth,
        'items-center rounded-none flex h-full justify-center w-full':
          _vm.fullWidth,
        [_vm.size]: true,
      },on:{"mouse":function($event){$event.stopPropagation();},"mousedown":event => event.stopPropagation()}},[(_vm.showCloseButton)?_c('woot-button',{staticClass:"absolute z-10 ltr:right-2 rtl:left-2 top-2",attrs:{"color-scheme":"secondary","icon":"dismiss","variant":"clear"},on:{"click":_vm.close}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }